import {
  Alert,
  Box,
  Grid,
  Paper,
  Rating,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  useMediaQuery,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import hiveCaptainBigLogo from "../../assets/updated/hive-captain/hive captian big logo.svg";
import hiveBg from "../../assets/updated/hive-captain/hive bg.png";
import pick_captain from "../../assets/landpage/pick_captain.svg";
import pick_captain_dark from "../../assets/landpage/pick_captain_dark.svg";
import React, { useEffect, useState } from "react";
import hiveCaptainLogo from "../../assets/yellow-hive/hive captian.svg";
// import copy from "../../assets/landpage/copyicon.svg";
import frame from "../../assets/landpage/frame.svg";
// import abcd from "../../assets/abcd.jpg";
import "./CaptainBees.css";
// import { Link } from 'react-router-dom';
import { baseCEXURL, getAllAffiliateUser } from "../../services/api";
import { Link, useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";

import logo1 from "../../assets/updated/hive-captain/captain1.svg";
import logo2 from "../../assets/updated/hive-captain/captain2.svg";
import logo3 from "../../assets/updated/hive-captain/captain3.svg";
import { makeStyles } from "@mui/styles";
import leadImage from "../../assets/updated/hive-captain/hiveCaptain.svg";
// Define the makeStyles hook
const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "1980px",
    margin: "80px auto",
    width: "100%",
    padding: "20px",
  },

  heroHeaderContainer: {
    display: "flex",
    gap: "40px",
    alignItems: "center",
    position: "relative",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  backgroundImg: {
    position: "absolute",
    right: 0,

    display: "flex",
    justifyContent: "flex-end",
    "& img": {
      width: "85%",
      marginLeft: "auto",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },

  imgContainer: {
    [theme.breakpoints.down("md")]: {
      width: "80%",
      margin: "auto",
      "& img": {
        width: "100%",
      },
    },
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    textAlign: "left",
    maxWidth: "500px",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      alignItems: "center",
    },
  },
  heading: {
    fontSize: "74px",
    fontWeight: "bold",
    marginBottom: "20px",
    [theme.breakpoints.down("md")]: {
      fontSize: "52px",
    },
  },
  description: {
    fontSize: "18px",
    marginBottom: "30px",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
  },
  button: {
    backgroundColor: "#FFB300",
    borderRadius: "2px",
    color: "#282828",
    py: 1,
    textTransform: "none",
    fontSize: "13px",
    fontWeight: "600",
    boxShadow: "none",
    mt: 2,
    "&:hover": {
      backgroundColor: "#FFD000",
      boxShadow: "none",
    },
  },

  howItWorksContainer: {
    maxWidth: "1280px",
    width: "100%",
    margin: "250px auto 100px auto",
  },
  howItWorksheading: {
    width: "100%",
    margin: "100px 0px 30px 0px !important",
    fontSize: "40px !important",
    fontWeight: "600 !important",
    color: theme.palette.text.primary,
    textAlign: "left",
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr 1fr",
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr ",
    },
  },

  card: {
    flex: "1 1 30%", // Adjust the basis value to control how many cards are shown per row
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "24px",
    textAlign: "left",
  },
  logo: {
    // Add your logo styles here
    width: "96px",
    height: "96px",

    "& img": {
      width: "100%",
      height: "100%",
    },
  },
  cardHeading: {
    marginTop: "24px !important",
    marginBottom: theme.spacing(1),
    fontSize: "20px !important",
    fontWeight: "600 !important",
    color: theme.palette.text.primary,
  },
  cardText: {
    fontSize: "14px !important",
    marginTop: "8px !important",
    color: theme.palette.text.secondary,
  },
  startNowRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "200px auto",
    "& h2": {
      fontSize: "62px",
      fontWeight: "600",
      color: theme.palette.text.primary,
      marginBottom: "30px",
      [theme.breakpoints.down("md")]: {
        fontSize: "42px",
        margin: "150px auto",
      },
    },
  },
  leadContainer: {
    display: "flex",
    justifyContent: "center",
    maxWidth: "600px",
    margin: "100px auto",
    padding: "10px",
    alignItems: "center",
    flexDirection: "column",
    "& img": {
      height: "150px",
      margin: "auto",
      marginBottom: "20px",
    },
    "& h3": {
      fontSize: "52px",
      fontWeight: "600",
      marginBottom: "20px",
      [theme.breakpoints.down("md")]: {
        fontSize: "32px",
      },
    },
    "& p": {
      fontSize: "16px",
      marginBottom: "20px",
      color: theme.palette.text.secondary,
    },
  },
}));

const Bees = () => {
  const [captainbees, setCaptainBees] = useState();
  const [topRatedCbees, setTopRatedCBees] = useState();
  const [initialRows, setInitialRows] = useState(10);
  const [totalRows, setTotalRows] = useState(10);
  const classes = useStyles();
  // const totalRows = captainbees.length;
  const handleShowMore = () => {
    setInitialRows(initialRows + 10); // Increase by the number of rows you want to load on each click
  };
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    getAllAffiliateUser().then((data) => {
      setCaptainBees(data);
      // Define the names of the users you want to filter
      const targetNames = [
        "Queen Bee",
        "Grandma Honey",
        "Golden Sea Sailing",
        "Blockmichael22",
      ];

      // Map over targetNames to preserve the order and find corresponding users
      const orderedFilteredUsers = targetNames
        .map((name) => data.find((user) => user.accname === name))
        .filter((user) => user !== undefined); // Filter out undefined entries (if any user is not found)

      // Set the ordered filtered users to state
      setTopRatedCBees(orderedFilteredUsers);
      setTotalRows(data.length);
    });
  }, []);

  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleSnackbarClick = () => {
    setOpen(true);

    const autoCloseTime = 1500; // 3 seconds

    setTimeout(() => {
      setOpen(false);
    }, autoCloseTime);
  };

  const copyClick = (code) => {
    navigator.clipboard.writeText(code);
    // alert("copied")
    handleSnackbarClick();
  };

  const [seltheme, setSeltheme] = useState(
    localStorage.getItem("selectedTheme") || "dark"
  );

  useEffect(() => {
    const handleStorageChange = (event) => {
      console.log(event);
      setSeltheme(event.currentTarget.localStorage.selectedTheme);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <>
      {/* <LandHeader /> */}
      <Box className={classes.container}>
        <Box className={classes.heroHeaderContainer}>
          <div className={classes.backgroundImg}>
            <img src={hiveBg} />
          </div>
          <div className={classes.imgContainer}>
            <img src={hiveCaptainBigLogo} />
          </div>
          <div className={classes.textContainer}>
            <p className={classes.heading}>Hive Captain</p>
            <p className={classes.description}>
              Is a central figure within the Indexx Hive network, tasked with
              leading and mentoring a team of Hive member in the crypto
              industry.
            </p>
            <Button
              variant="contained"
              disableTouchRipple
              className={classes.button}
              onClick={() => navigate("/sign-up")}
            >
              <span style={{ textTransform: "capitalize" }}>
                Sign Up as Hive Captain
              </span>
            </Button>
          </div>
        </Box>
        <Box className={classes.howItWorksContainer}>
          <Typography className={classes.howItWorksheading}>
            How it Works
          </Typography>

          <div className={classes.gridContainer}>
            <Box className={classes.card}>
              <div className={classes.logo}>
                <img src={logo1} />
              </div>
              <Typography className={classes.cardHeading}>
                1. Sign Up as Hive Captain
              </Typography>
              <Typography className={classes.cardText}>
                Become a Hive Captain! Subscribe with $200 monthly and lead the
                community with exclusive INEX rewards.
              </Typography>
            </Box>
            <Box className={classes.card}>
              <div className={classes.logo}>
                <img src={logo2} />
              </div>
              <Typography className={classes.cardHeading}>
                2. Refer Friends
              </Typography>
              <Typography className={classes.cardText}>
                Refer friends to join and unlock exclusive rewards! The more
                friends you bring, the more you earn together.
              </Typography>
            </Box>
            <Box className={classes.card}>
              <div className={classes.logo}>
                <img src={logo3} />
              </div>
              <Typography className={classes.cardHeading}>
                3. Earn Commission
              </Typography>
              <Typography className={classes.cardText}>
                Earn 5% commission on purchases by your Hive Members and 10%
                profit share from their monthly portfolio growth!
              </Typography>
            </Box>
          </div>
        </Box>
        <Box className={classes.startNowRoot}>
          <h2>Start Earning Now</h2>

          <Button
            variant="contained"
            disableTouchRipple
            className={classes.button}
            onClick={() => navigate("/sign-up")}
          >
            <span style={{ textTransform: "capitalize" }}>
              Sign Up as Hive Captain
            </span>
          </Button>
        </Box>

        <Box className={classes.leadContainer}>
          <img src={leadImage} />
          <h3>Lead.Guide.Thrive</h3>
          <p>
            Hive Captain guide collective decisions, participate in trading
            activities, and earn commissions from colony purchases. With access
            to exclusive benefits like investment algorithms and learning
            resources, Hive Captain play a crucial role in fostering growth
            within their colony. Through the Hive Captain Thriving Plan, they
            have the opportunity to thrive personally while shaping the future
            of decentralized finance within the Indexx ecosystem.
          </p>
          <Button
            variant="contained"
            disableTouchRipple
            className={classes.button}
            onClick={() => navigate("/sign-up")}
          >
            <span style={{ textTransform: "capitalize" }}>
              Sign Up as Hive Captain
            </span>
          </Button>
        </Box>
        <div style={{ margin: "250px" }}></div>
        <div>
          <Typography
            variant="text"
            fontSize={isMobile ? "32px" : "46px"}
            fontWeight={500}
            textAlign={"center"}
            mt={2}
            maxWidth={500}
            mb={100}
            marginBottom="20px"
          >
            Hive Captains of the week
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 2,
            }}
          >
            <Box
              sx={{
                width: "60%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Grid
                container
                // columns={{ xs: 1, sm: 12, md: 12 }}
                spacing={{ xs: 10, md: 2 }}
                maxWidth={"840px"}
              >
                {topRatedCbees?.map((item) => (
                  <Grid item xs={12} sm={12} md={3}>
                    <>
                      <Link
                        to={`/captainbee/${item.Username}`}
                        style={{
                          textDecoration: "none",
                          color: "var(--body_color)",
                        }}
                      >
                        <Stack
                          direction="column"
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Box
                            sx={{
                              width: "171px",
                              height: "169px",
                              // backgroundImage: `url(${frame})`,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "center",
                              position: "relative",
                              cursor: "pointer",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              alignSelf: "center",
                              // border:"none"
                            }}
                          >
                            <div
                              style={{
                                width: "171px",
                                height: "169px",
                                position: "absolute",
                                zIndex: "1",
                              }}
                            >
                              <img
                                src={frame}
                                style={{ width: "100%", height: "100%" }}
                              />
                            </div>
                            {item?.photoIdFileurl === null ||
                            item?.photoIdFileurl === undefined ? null : (
                              <Box className="hexagon">
                                <img
                                  alt=""
                                  src={item?.photoIdFileurl}
                                  // src={(item?.photoIdFileurl === undefined || item?.photoIdFileurl === null) ? frame : item?.photoIdFileurl}
                                  width={"63px"}
                                  height={"66px"}
                                  ml={"-6px"}
                                  border={"none"}
                                />
                              </Box>
                            )}
                          </Box>

                          <Typography
                            variant="text"
                            fontSize={"20px"}
                            fontWeight={700}
                            mt={2}
                          >
                            {item?.accname}
                          </Typography>
                          <Typography
                            variant="text"
                            fontSize={"13px"}
                            fontWeight={400}
                            mt={1}
                            mb={3}
                          >
                            {item?.firstname + " " + item?.lastname}
                          </Typography>
                        </Stack>
                      </Link>
                    </>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>

          {isMobile && (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 10,
                  mb: 10,
                }}
              >
                <Box
                  component="img"
                  alt=""
                  src={seltheme === "dark" ? pick_captain_dark : pick_captain}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 2,
                }}
              >
                <Box
                  sx={{
                    width: "60%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Grid
                    container
                    // columns={{ xs: 1, sm: 12, md: 12 }}
                    spacing={{ xs: 12, md: 2 }}
                    maxWidth={"840px"}
                  >
                    {captainbees?.map((item) => (
                      <Grid item xs={12} sm={12} md={3}>
                        <>
                          <Stack
                            direction="column"
                            justifyContent={"center"}
                            alignItems={"center"}
                            width={"100%"}
                            mb={4}
                          >
                            <Box
                              sx={{
                                width: "171px",
                                height: "169px",
                                // backgroundImage: `url(${frame})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "center",
                                position: "relative",
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                alignSelf: "center",
                                // border:"none"
                              }}
                            >
                              <div
                                style={{
                                  width: "171px",
                                  height: "169px",
                                  position: "absolute",
                                  zIndex: "1",
                                }}
                              >
                                <img
                                  src={frame}
                                  style={{ width: "100%", height: "100%" }}
                                />
                              </div>
                              {item?.photoIdFileurl === null ||
                              item?.photoIdFileurl === undefined ? null : (
                                <Box className="hexagon">
                                  <img
                                    alt=""
                                    src={item?.photoIdFileurl}
                                    // src={(item?.photoIdFileurl === undefined || item?.photoIdFileurl === null) ? frame : item?.photoIdFileurl}
                                    width={"63px"}
                                    height={"66px"}
                                    ml={"-6px"}
                                    border={"none"}
                                  />
                                </Box>
                              )}
                            </Box>

                            <Typography
                              variant="text"
                              fontSize={"20px"}
                              fontWeight={700}
                              mt={2}
                            >
                              {item?.accname}
                            </Typography>

                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                alignSelf: "center",
                                // gap:1
                              }}
                            >
                              <Stack
                                direction="column"
                                justifyContent={"center"}
                                alignItems={"flex-start"}
                                minWidth={"95px"}
                                alignSelf={"baseline"}
                              >
                                <Typography
                                  variant="text"
                                  fontSize={"13px"}
                                  fontWeight={400}
                                  mt={1}
                                >
                                  Profile Link :
                                </Typography>
                                <Typography
                                  variant="text"
                                  fontSize={"13px"}
                                  fontWeight={400}
                                  mt={1}
                                >
                                  Referral Code :
                                </Typography>
                                <Typography
                                  variant="text"
                                  fontSize={"13px"}
                                  fontWeight={400}
                                  mt={1.5}
                                >
                                  Rating :
                                </Typography>
                              </Stack>
                              <Stack
                                direction="column"
                                justifyContent={"center"}
                                alignItems={"flex-start"}
                                minWidth={"50%"}
                                maxWidth={"80%"}
                              >
                                <Typography
                                  variant="text"
                                  fontSize={"13px"}
                                  fontWeight={400}
                                  mt={1}
                                  noWrap
                                  maxWidth={"100%"}
                                >
                                  <Link
                                    to={`/captainbee/${item.Username}`}
                                    style={{
                                      textDecoration: "none",
                                      color: "var(--body_color)",
                                    }}
                                  >
                                    {"hive.indexx.ai/captainbee/" +
                                      item.Username}
                                  </Link>
                                </Typography>
                                <Typography
                                  variant="text"
                                  fontSize={"13px"}
                                  fontWeight={400}
                                  mt={1}
                                >
                                  <>
                                    {item?.userData?.referralCode ? (
                                      <>
                                        <Link
                                          to={
                                            baseCEXURL +
                                            "/indexx-exchange/buy-sell/get-started-honeybee?referral=" +
                                            item?.userData?.referralCode
                                          }
                                          style={{
                                            textDecoration: "none",
                                            color: "var(--body_color)",
                                          }}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {item?.userData?.referralCode}{" "}
                                        </Link>
                                        <ContentCopyIcon
                                          fontSize="15px"
                                          onClick={() =>
                                            copyClick(
                                              item?.userData?.referralCode
                                            )
                                          }
                                          style={{ cursor: "pointer" }}
                                        />
                                        <Snackbar
                                          open={open}
                                          autoHideDuration={1500} // You can set a default auto-hide duration here (6 seconds in this example)
                                          onClose={handleClose}
                                          sx={{
                                            mt: 10,
                                          }}
                                          anchorOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                          }}
                                        >
                                          <Alert
                                            onClose={handleClose}
                                            severity="success"
                                            sx={{
                                              background: "#ffb300",
                                              color: "#000",
                                            }}
                                          >
                                            Referral Code Copied
                                          </Alert>
                                        </Snackbar>
                                      </>
                                    ) : (
                                      <Box className="code">
                                        {"No Refferal Code"}
                                      </Box>
                                    )}
                                  </>
                                </Typography>
                                <Typography
                                  variant="text"
                                  fontSize={"13px"}
                                  fontWeight={400}
                                  mt={1}
                                >
                                  <Rating
                                    name="read-only"
                                    value={item.star ? item.star : 4}
                                    readOnly
                                    size="large"
                                  />
                                </Typography>
                              </Stack>
                            </Box>
                          </Stack>
                        </>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
            </>
          )}
          {!isMobile && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                mt: 12,
              }}
            >
              <TableContainer
                component={Paper}
                style={{
                  marginTop: 40,
                  width: "70%",
                  //   backgroundColor: `${darkMode ? '#040404' : '#fbfbfb'}`
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow
                      style={{ backgroundColor: "#FFB300", color: "black" }}
                    >
                      <TableCell>
                        <Typography
                          variant="text"
                          fontSize={"20px"}
                          fontWeight={700}
                          color={"black"}
                        >
                          Hive Captain
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="text"
                          fontSize={"20px"}
                          fontWeight={700}
                          color={"black"}
                        >
                          Profile Link
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="text"
                          fontSize={"20px"}
                          fontWeight={700}
                          color={"black"}
                        >
                          Referral Code
                        </Typography>
                      </TableCell>
                      {/* <TableCell>
                                        <Typography variant="text" fontSize={"15px"} fontWeight={700}>
                                            TeamHive Captain Referal Code
                                        </Typography>
                                    </TableCell> */}
                      <TableCell>
                        <Typography
                          variant="text"
                          fontSize={"20px"}
                          fontWeight={700}
                          color={"black"}
                        >
                          Rating
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {captainbees?.slice(0, initialRows).map((collection) => (
                      <TableRow
                        key={collection.id}
                        style={{ textDecoration: "none" }}
                      >
                        <TableCell>
                          <Link
                            to={`/captainbee/${collection.Username}`}
                            style={{
                              textDecoration: "none",
                              color: "var(--body_color)",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignSelf: "center",
                              }}
                            >
                              {/* <Box component="img" alt="Collection Image" src={(collection?.photoIdFileurl === undefined || collection?.photoIdFileurl === null )? frame : collection?.photoIdFileurl} sx={{ width: 80, height: 80 }} /> */}
                              <Box
                                sx={{
                                  minWidth: "80px",
                                  minHeight: "80px",
                                  // backgroundImage: `url(${frame})`,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "center",
                                  position: "relative",
                                  cursor: "pointer",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  alignSelf: "center",
                                  // border:"none"
                                }}
                              >
                                <div
                                  style={{
                                    width: "80px",
                                    height: "80px",
                                    position: "absolute",
                                    zIndex: "1",
                                  }}
                                >
                                  <img
                                    src={frame}
                                    style={{ width: "100%", height: "100%" }}
                                  />
                                </div>

                                {collection?.photoIdFileurl === null ||
                                collection?.photoIdFileurl ===
                                  undefined ? null : (
                                  <Box className="small-hexagon">
                                    <img
                                      alt=""
                                      // src={abcd}
                                      src={collection?.photoIdFileurl}
                                      // src={(collection?.photoIdFileurl === undefined || collection?.photoIdFileurl === null) ? frame : collection?.photoIdFileurl}
                                      width={"30px"}
                                      height={"31px"}
                                      ml={"-2px"}
                                      border={"none"}
                                    />
                                  </Box>
                                )}
                              </Box>
                              <Box alignSelf={"center"} ml={2}>
                                <Typography
                                  variant="text"
                                  fontSize={"20px"}
                                  fontWeight={700}
                                  textAlign={"center"}
                                  style={{ verticalAlign: "center" }}
                                  color={"var(--body_color)"}
                                >
                                  {collection?.accname}
                                </Typography>
                                <br />
                                <Typography
                                  variant="text"
                                  fontSize={"13px"}
                                  fontWeight={400}
                                  textAlign={"center"}
                                  style={{ verticalAlign: "center" }}
                                  color={"var(--body_color)"}
                                >
                                  {collection?.firstname +
                                    " " +
                                    collection?.lastname}
                                </Typography>
                              </Box>
                            </Box>
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Link
                            to={`/captainbee/${collection.Username}`}
                            style={{
                              textDecoration: "none",
                              color: "var(--body_color)",
                            }}
                          >
                            {"hive.indexx.ai/captainbee/" + collection.Username}
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignSelf: "center",
                              gap: 1,
                            }}
                            className="reff"
                          >
                            <>
                              {collection?.userData?.referralCode ? (
                                <>
                                  {/* <Link
                                                            to="/view-ref" 
                                                            style={{ textDecoration: "none", color: "var(--body_color)"}}
                                                            // target="_blank"
                                                            rel="noopener noreferrer"> */}
                                  <Button
                                    variant="contained"
                                    disableTouchRipple
                                    sx={{
                                      backgroundColor: "#FFB300",
                                      borderRadius: "2px",
                                      color: "#282828",
                                      width: "100%",
                                      //   px: 10,
                                      //   py: 1,
                                      textTransform: "none",
                                      fontSize: "13px",
                                      fontWeight: 700,
                                      boxShadow: "none",
                                      "&:hover": {
                                        backgroundColor: "#FFD000",
                                        boxShadow: "none",
                                      },
                                    }}
                                    onClick={() =>
                                      navigate("/view-ref", {
                                        state: {
                                          id: 1,
                                          accname: collection.accname,
                                          firstname: collection.firstname,
                                          lastname: collection.lastname,
                                          refcode:
                                            collection.userData.referralCode,
                                          photoIdFileurl:
                                            collection.photoIdFileurl,
                                        },
                                      })
                                    }
                                  >
                                    Hive Member/Hive Captain
                                  </Button>
                                  {/* </Link> */}
                                  {/* <Link to={baseCEXURL + "/indexx-exchange/buy-sell/get-started-honeybee?referral=" + (collection?.userData?.referralCode)} style={{ textDecoration: "none", color: "var(--body_color)" }}
                                                            target="_blank"
                                                            rel="noopener noreferrer">
                                                            {(collection?.userData?.referralCode)}
                                                        </Link> */}
                                  {/* <ContentCopyIcon  fontSize='15px' onClick={() => copyClick(collection?.userData?.referralCode)} style={{cursor:"pointer"}}/>
                                                            <Snackbar
                                                                open={open}
                                                                autoHideDuration={1500} // You can set a default auto-hide duration here (6 seconds in this example)
                                                                onClose={handleClose}
                                                                sx={{
                                                                    mt:10,
                                                                }}
                                                                anchorOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'right',
                                                                }}
                                                            >
                                                                <Alert onClose={handleClose} severity="success" sx={{background:"#ffb300", color:"#000"}}>
                                                                Referral Code Copied
                                                                </Alert>
                                                            </Snackbar> */}
                                </>
                              ) : (
                                <Box className="code">{"No Refferal Code"}</Box>
                              )}
                            </>
                          </Box>
                        </TableCell>
                        {/* <TableCell>
                                            <Box sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignSelf: "center",
                                                gap:1
                                            }}
                                                className="reff"
                                            >
                                                <>
                                                    {collection?.userData?.referralCode ? (
                                                        <>
                                                        <Link to={"/sign-up?referral=" + (collection?.userData?.referralCode)} style={{ textDecoration: "none", color: "var(--body_color)" }}
                                                            // target="_blank"
                                                            rel="noopener noreferrer">
                                                            {(collection?.userData?.referralCode)}
                                                        </Link>
                                                            <ContentCopyIcon  fontSize='15px' onClick={() => copyClick(collection?.userData?.referralCode)} style={{cursor:"pointer"}}/>
                                                            <Snackbar
                                                                open={open}
                                                                autoHideDuration={1500} // You can set a default auto-hide duration here (6 seconds in this example)
                                                                onClose={handleClose}
                                                                sx={{
                                                                    mt:10,
                                                                }}
                                                                anchorOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'right',
                                                                }}
                                                            >
                                                                <Alert onClose={handleClose} severity="success" sx={{background:"#ffb300", color:"#000"}}>
                                                                Referral Code Copied
                                                                </Alert>
                                                            </Snackbar>
                                                        </>
                                                    ) : (
                                                        <Box className="code">
                                                            {"No Refferal Code"}
                                                        </Box>
                                                    )}
                                                </>

                                            </Box>

                                        </TableCell> */}
                        <TableCell>
                          <Rating
                            name="read-only"
                            value={collection.star ? collection.star : 4}
                            readOnly
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {initialRows < totalRows && (
                <Button
                  variant="contained"
                  disableTouchRipple
                  onClick={handleShowMore}
                  sx={{
                    backgroundColor: "#FFB300",
                    borderRadius: "2px",
                    color: "#282828",
                    px: isMobile ? 6.1 : 10,
                    py: 1,
                    textTransform: "none",
                    fontSize: `${isMobile ? "9px" : "13px"}`,
                    fontWeight: "700",
                    boxShadow: "none",
                    mt: 3,
                    "&:hover": {
                      backgroundColor: "#FFD000",
                      boxShadow: "none",
                    },
                  }}
                >
                  Show More
                </Button>
              )}
            </Box>
          )}
        </div>
      </Box>
    </>
  );
};

export default Bees;
