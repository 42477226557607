import React from "react";

import bee from "../../assets/subnav/Honeybee.png";
import hat from "../../assets/subnav/captain bee.png";
import hive from "../../assets/subnav/indexx hive.png";

import bee_dark from "../../assets/subnav/darkmode/honeybee.png";
import hat_dark from "../../assets/subnav/darkmode/captain bee.png";
import hive_dark from "../../assets/subnav/darkmode/indexx hive.png";

// import book from "../../assets/subnav/Book_.svg";
// import calendar from "../../assets/subnav/calendar 1.svg";
// import honey from "../../assets/subnav/honey_ 1.svg";
// import jar from "../../assets/subnav/jar-icon 1.svg";
// import jelly from "../../assets/subnav/royal jelly icon 1.svg";
// import nectar from "../../assets/subnav/nectar 2 1.svg";
// import nuggets from "../../assets/subnav/nuggets 1.svg";
// import pro from "../../assets/subnav/pro-icon 1.svg";
// import setup from "../../assets/subnav/set up icon 1.svg";
// import signup from "../../assets/subnav/sign up 1.svg";
// import overview from "../../assets/subnav/tag new 1.svg";

import "./LandHeader.css";
import { Link, useLocation } from "react-router-dom";
import { Button, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useState, useEffect } from "react";
import { baseCEXURL } from "../../services/api";
import IconicHeader from "./IconicHeader";

const LandHeader = () => {
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState("Crypto");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile2 = useMediaQuery(theme.breakpoints.down("sm"));

  const [seltheme, setSeltheme] = useState(
    localStorage.getItem("selectedTheme") || "dark"
  );

  useEffect(() => {
    const handleStorageChange = (event) => {
      console.log(event);
      setSeltheme(event.currentTarget.localStorage.selectedTheme);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  {
    /* //   className="row row-cols-1 row-cols-md-4 g-4 up-logos" */
  }
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <IconicHeader onChange={handleTabChange} />
    // <div
    //   style={{
    //     position: "fixed",
    //     top: `${isMobile ? "60px" : "70px"}`,
    //     width: "100%",
    //     zIndex: 999,
    //     background: "var(--main-body)",
    //     padding: "10px 0px",
    //   }}
    // >
    //   <div
    //     className="cover-page up-logos"
    //     style={{
    //       alignItems: "center",
    //       maxWidth: "700px",
    //       display: "flex",
    //       flexWrap: "wrap",
    //       justifyContent: "space-between",
    //       margin: "auto",
    //     }}
    //   >
    //     <div className="">
    //       <a href="/bees">
    //         <div className="card">
    //           {seltheme === "dark" ? (
    //             <img src={hat_dark} className="card-img-top" alt="..." />
    //           ) : (
    //             <img src={hat} className="card-img-top" alt="..." />
    //           )}
    //           <div className="card-body">
    //             <h5 className="card-title">Hive Captain</h5>
    //             <Typography
    //               component="p"
    //               sx={
    //                 location.pathname === "/bees"
    //                   ? {
    //                       height: "1px",
    //                       width: "58px",
    //                       backgroundColor: "var(--dark_text)",
    //                     }
    //                   : null
    //               }
    //             ></Typography>
    //           </div>
    //         </div>
    //       </a>
    //     </div>
    //     <div className="">
    //       <a href="/honey-bees">
    //         <div className="card">
    //           {seltheme === "dark" ? (
    //             <img
    //               src={bee_dark}
    //               className="card-img-top"
    //               alt="..."
    //               style={{ marginTop: `${isMobile ? "0" : "5px"}` }}
    //             />
    //           ) : (
    //             <img
    //               src={bee}
    //               className="card-img-top"
    //               alt="..."
    //               style={{ marginTop: `${isMobile ? "0" : "5px"}` }}
    //             />
    //           )}
    //           <div className="card-body">
    //             <h5 className="card-title">Hive Member</h5>
    //             <Typography
    //               component="p"
    //               sx={
    //                 location.pathname === "/honey-bees"
    //                   ? {
    //                       height: "1px",
    //                       width: "58px",
    //                       backgroundColor: "var(--dark_text)",
    //                     }
    //                   : null
    //               }
    //             ></Typography>
    //           </div>
    //         </div>
    //       </a>
    //     </div>

    //     <a href={`https://indexx.ai/auth/login?redirectWebsiteLink=hive`}>
    //       <Button
    //         variant="contained"
    //         disableTouchRipple
    //         sx={{
    //           backgroundColor: "#FFB300",
    //           borderRadius: "2px",
    //           color: "#282828",
    //           //   px: isMobile ? 0 : 5,
    //           //   py: 1,
    //           textTransform: "none",
    //           fontSize: "13px",
    //           fontWeight: 600,
    //           boxShadow: "none",

    //           "&:hover": {
    //             backgroundColor: "#FFD000",
    //             boxShadow: "none",
    //           },
    //         }}
    //       >
    //         Hive Captain Login
    //       </Button>
    //     </a>
    //   </div>
    // </div>
  );
};

export default LandHeader;
