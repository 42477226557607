import React from "react";
import { makeStyles } from "@mui/styles";
import { TextField, Button, InputAdornment } from "@mui/material";
import subscribeImg from "../assets/updated/subscribeImg.svg";
import MessageIcon from "@mui/icons-material/Message";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    padding: "20px",
    maxWidth: "980px",
    width: "100%",
    margin: "300px auto 0px auto",
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      flex: "1",
    },
  },
  image: {
    maxWidth: "100%",
    borderRadius: theme.shape.borderRadius,
  },
  contentContainer: {
    textAlign: "center",
    "& h5": {
      fontSize: "32px",
      fontWeight: "bold",
      marginBottom: "15px",
    },
    "& p": {
      fontSize: "16px",
      marginBottom: "10px",
    },
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
      flex: "1",
    },
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "center",
    },
  },
}));

const SubscribeForm = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* Image Section */}
      <div className={classes.imageContainer}>
        <img src={subscribeImg} alt="Placeholder" className={classes.image} />
      </div>

      {/* Content Section */}
      <div className={classes.contentContainer}>
        <h5>Don’t Miss Our New Recent Updates!</h5>
        <p>
          Subscribe to our email updates to know latest happenings in crypto and
          the Hive
        </p>
        <div className={classes.formContainer}>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Enter your Email"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MessageIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button variant="contained" color="primary">
            <span
              style={{
                textTransform: "capitalize",
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              Subscribe
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SubscribeForm;
