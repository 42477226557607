import * as React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/system";
import { useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import hat from "../../assets/yellow-hive/member.svg";
import hive from "../../assets/yellow-hive/hive.png";
import hive_exch from "../../assets/yellow-hive/exhange.png";

import hat_dark from "../../assets/yellow-hive/member.svg";
import hive_dark from "../../assets/yellow-hive/hive.png";
import hive_exch_dark from "../../assets/yellow-hive/exhange.png";
import loginIcon from "../../assets/yellow-hive/login.png";
import signupIcon from "../../assets/yellow-hive/register.png";

const CustomTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  color: "#FEBA00",
  minWidth: 0,
  width: "150px",
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  margin: "0 5px",
  padding: "12px 16px",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  alignItems: "center",
  position: "relative",
  background: "transparent !important",
  "&.active": {
    color: theme.palette.text.primary,
    "&::after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: "calc(50% - 10px)",
      width: "16px",
      borderBottom: `3px solid ${theme.palette.text.primary}`,
    },
  },
  "&:hover": {
    color: theme.palette.text.primary,
    background: "transparent !important",
    "&::after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: "calc(50% - 10px)",
      width: "16px",
      borderBottom: `3px solid ${theme.palette.text.primary}`,
    },
  },
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: "3px",
    background: "transparent",
  },
}));

export default function IconicHeader({ selectedTab, onChange }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  //   React.useEffect(() => {
  //     const email = localStorage.getItem("email");
  //     setIsLoggedIn(!!email);
  //     if (email) {
  //       checkByemail(String(email))
  //         .then((res) => {
  //           if (res && res.userType) {
  //             setUserType(res.userType);
  //           }
  //           setLoading(false);
  //         })
  //         .catch((err) => setLoading(false));
  //     } else {
  //       setLoading(false);
  //     }
  //   }, []);

  const tabsData = [
    {
      label: "Indexx Hive",
      light: hive,
      dark: hive_dark,
      path: "/",
      search: " ",
    },
    {
      label: "Hive Member",
      light: hat,
      dark: hat_dark,
      path: "/hive-member",
      search: "",
    },
    {
      label: "Exchange",
      light: hive_exch,
      dark: hive_exch_dark,
      path: "https://cex.indexx.ai",
      search: " ",
    },
    {
      label: "Sign up",
      light: signupIcon,
      dark: signupIcon,
      path: "/sign-up",
      search: "",
    },
    {
      label: "Login",
      light: loginIcon,
      dark: loginIcon,
      path: "https://indexx.ai/auth/login?redirectWebsiteLink=exchange",
      search: " ",
    },
  ];

  //   if (userType !== 'Indexx Exchange') {
  //     tabsData.push({
  //       label: 'Hive',
  //       light: hiveHive,
  //       dark: hiveHive,
  //       path: '/indexx-exchange/dashboard',
  //     });
  //   }

  const getSelectedTab = () => {
    const currentPath = location.pathname;
    const currentSearch = location.search;

    const matchedTab = tabsData.find(
      (tab) =>
        tab.path === currentPath &&
        (tab.search ? currentSearch.includes(tab.search) : true)
    );
    return matchedTab ? matchedTab.label : "";
  };

  const [selectedTabState, setSelectedTabState] = React.useState(
    getSelectedTab()
  );

  React.useEffect(() => {
    setSelectedTabState(getSelectedTab());
  }, [location]);

  const handleChange = (event, newValue) => {
    const label = event.currentTarget.innerText;
    const selectedTabData = tabsData.find((tab) => tab.label === label);

    if (selectedTabData) {
      if (selectedTabData.path.startsWith("http")) {
        // If the path is an external URL, open it in a new tab
        window.open(selectedTabData.path, "_blank");
      } else {
        // Navigate to internal paths
        navigate(`${selectedTabData.path}`);
      }
      //   onChange(event, label);
    }
  };

  let maxWidthTabContainer = "850px";

  const TabView = CustomTab;
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        maxWidth: maxWidthTabContainer,
        paddingTop: "100px",
        margin: "0px auto 0px auto",
        position: "relative",
        zIndex: "1111",
      }}
    >
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        centered={true}
        variant="scrollable"
        scrollButtons={"auto"}
        sx={{
          width: "100%",
          background: "none",
          "& .MuiTabs-indicator": {
            display: "none",
          },
          [theme.breakpoints.down("md")]: {
            "& .MuiTabs-scrollButtons": {
              display: "flex",
            },
            "& .MuiTabScrollButton-root": {
              padding: "25px",
              color: "white",
              fontSize: "24px",
            },
          },
        }}
      >
        {tabsData.map((tab, index) => (
          <TabView
            key={index}
            icon={
              <img
                src={theme.palette.mode === "dark" ? tab.dark : tab.light}
                style={{ height: "25px", marginBottom: "0px" }}
              />
            }
            iconPosition="top"
            label={tab.label}
            disableRipple
            className={selectedTab === tab.label ? "active" : ""}
          />
        ))}
      </Tabs>
    </Box>
  );
}
