import orange from "../assets/powerpack/Orange ribbon.svg";
import royal from "../assets/powerpack/unnamed (6).png";
import blue from "../assets/powerpack/blue hat.svg";
import copper from "../assets/powerpack/copper hat.svg";
import gold from "../assets/powerpack/gold hat.svg";
import green from "../assets/powerpack/green Ribbon 1.svg";
import platinum from "../assets/powerpack/platinum hat.svg";
import purple from "../assets/powerpack/purple ribbon.svg";

export const PackData = [
  {
    id: "1",
    name: "Starter Pack",
    photo: green,
    price: "300",
    coins: "50",
    features: ["50 INEX Tokens ($2 each)", "Crypto Beginner Ebooks"],
    level: "normal",
    flip: false,
  },
  {
    id: "2",
    name: "Excel Pack",
    photo: orange,
    price: "500",
    coins: "150",
    features: [
      "150 INEX Tokens ($2 each)",
      "Crypto Beginner Ebooks",
      "Crypto Advanced Ebooks",
    ],
    level: "normal",
    flip: false,
  },
  {
    id: "3",
    name: "Pro Pack",
    photo: purple,
    price: "700",
    coins: "250",
    features: [
      "250 INEX Tokens ($2 each)",
      "Crypto Beginner Ebook",
      "Crypto Advanced Ebook",
      "Stocks Beginner Ebook",
      "Educational Crypto Courses",
      "(Exclusive to Indexx Academy)",
    ],
    level: "normal",
    flip: true,
  },
  {
    id: "4",
    name: "Captain Pack",
    photo: blue,
    price: "1,500",
    coins: "500",
    features: [
      "500 INEX Tokens ($2 each)",
      "Indexx Hive College Subscription",
      "Crypto Beginner Ebooks",
      "Crypto Advanced Ebooks",
      "Educational Crypto Courses",
      "(Exclusive to Indexx Academy)",
      "Private Hive Walkthrough",
    ],
    level: "Hive Captain",
    flip: true,
  },
  {
    id: "5",
    name: "Copper Pack",
    photo: copper,
    price: "3,500",
    coins: "1500",
    features: [
      "1500 INEX Tokens ($2 each)",
      "Indexx Hive College Subscription",
      "Crypto Beginner Ebooks",
      "Crypto Advanced Ebooks",
      "Educational Crypto Course",
      "Indexx Exchange Course",
      "Indexx Swap Course",
      "(Exclusive to Indexx Academy)",
      "Private Hive Walkthrough",
      "Discount on Indexx Shop Product",
    ],
    level: "Hive Captain",
    flip: true,
  },
  {
    id: "6",
    name: "Gold Pack",
    photo: gold,
    price: "5,500",
    coins: "2500",
    features: [
      "2500 INEX Tokens ($2 each)",
      "Indexx Hive College Subscription",
      "Crypto Beginner Ebooks",
      "Crypto Advanced Ebooks",
      "Educational Crypto Course",
      "Indexx Exchange Course",
      "Indexx Swap Course",
      "(Exclusive to Indexx Academy)",
      "Private Hive Walkthrough",
      "Private Coaching and Guidance",
      "Discount on Indexx Shop Products",
    ],
    level: "Hive Captain",
    flip: true,
  },
  {
    id: "7",
    name: "Platinum Pack",
    photo: platinum,
    price: "9,000",
    coins: "4500",
    features: [
      "4500 INEX Tokens ($2 each)",
      "Indexx Hive College Subscription",
      "Crypto Beginner Ebooks",
      "Crypto Advanced Ebooks",
      "Educational Crypto Course",
      "Indexx Exchange Course",
      "Indexx Swap Course",
      "(Exclusive to Indexx Academy)",
      "Private Hive Walkthrough",
      "Private Coaching and Guidance",
      "Discount on Indexx Shop Products",
      "Free XNFT Gifts",
      "MLM %Commission",
      "Premium Support (1 on 1)",
    ],
    level: "Hive Captain",
    flip: true,
  },
  {
    id: "8",
    name: "Royal Pack",
    photo: royal,
    price: "15,000",
    coins: "7500",
    features: [
      "7500 INEX Tokens ($2 each)",
      "Indexx Hive College Subscription",
      "Crypto Beginner Ebooks",
      "Crypto Advanced Ebooks",
      "Educational Crypto Course",
      "Indexx Exchange Course",
      "Indexx Swap Course",
      "(Exclusive to Indexx Academy)",
      "Private Hive Walkthrough",
      "Private Coaching and Guidance",
      "Discount on Indexx Shop Products",
      "Free XNFT Gifts",
      "MLM %Commission",
      "Premium Support (1 on 1)",
    ],
    level: "Hive Captain",
    flip: true,
  },
];
