import React from "react";

import hat from "../../assets/subnav/captain bee.png";
import hive from "../../assets/subnav/indexx hive.png";
// import waggle from "../../assets/subnav/wiggle icon 1.svg";
import man from "../../assets/subnav/man.png";
// import pro from "../../assets/subnav/pro-icon 1.svg";
import registration from "../../assets/subnav/registration icon.png";
import hive_exch from "../../assets/subnav/hive exchange black.png";

import hat_dark from "../../assets/subnav/darkmode/captain bee.png";
import hive_dark from "../../assets/subnav/darkmode/indexx hive.png";
import bee from "../../assets/subnav/Honeybee.png";

import bee_dark from "../../assets/subnav/darkmode/honeybee.png";

// import waggle_dark from "../../assets/subnav/darkmode/waggle icon white.svg";
import man_dark from "../../assets/subnav/darkmode/man white.png";
// import pro_dark from "../../assets/subnav/darkmode/pro-icon 2.svg";
import registration_dark from "../../assets/subnav/darkmode/registration icon white.png";
import hive_exch_dark from "../../assets/subnav/darkmode/hive exchange white.png";
// import book from "../../assets/subnav/Book_.svg";
// import bee from "../../assets/subnav/bee wings BnW 1.svg";
// import calendar from "../../assets/subnav/calendar 1.svg";
// import honey from "../../assets/subnav/honey_ 1.svg";
// import jar from "../../assets/subnav/jar.svg";
// import jelly from "../../assets/subnav/royal jelly icon 1.svg";
// import nectar from "../../assets/subnav/nectar 2 1.svg";
// import nuggets from "../../assets/subnav/nuggets 1.svg";
// import setup from "../../assets/subnav/set up icon 1.svg";

// import signup from "../../assets/subnav/sign up 1.svg";
// import overview from "../../assets/subnav/tag new 1.svg";

import "./SubHeader.css";
import { Link, useLocation } from "react-router-dom";
import { Typography, useMediaQuery } from "@mui/material";
import { HashLink } from "react-router-hash-link";
import { useTheme } from "@emotion/react";
import { useState } from "react";
import { useEffect } from "react";
import IconicHeader from "./IconicHeader";

const SubHeader = () => {
  const location = useLocation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [seltheme, setSeltheme] = useState(
    localStorage.getItem("selectedTheme") || "dark"
  );

  useEffect(() => {
    const handleStorageChange = (event) => {
      console.log(event);
      setSeltheme(event.currentTarget.localStorage.selectedTheme);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);
  return (
    <IconicHeader />
    // <div
    //   style={{
    //     position: "fixed",
    //     top: `${isMobile ? "60px" : "70px"}`,
    //     width: "100%",
    //     zIndex: 999,
    //     background: "var(--main-body)",
    //     padding: "20px 0px",
    //   }}
    //   className="sub-head"
    // >
    //   <div className="container cover-page">
    //     <div
    //       className=" g-4 up-logos"
    //       style={{
    //         justifyContent: "space-between",
    //         display: "flex",
    //         maxWidth: "800px",
    //         margin: "auto",
    //         flexWrap: "wrap",
    //         paddingTop: `${isMobile ? "25px" : "0"}`,
    //       }}
    //     >
    //       <div className="">
    //         <Link to="/">
    //           <div className="card">
    //             {seltheme === "dark" ? (
    //               <img src={hive_dark} className="card-img-top" alt="..." />
    //             ) : (
    //               <img src={hive} className="card-img-top" alt="..." />
    //             )}
    //             <div className="card-body">
    //               <h5 className="card-title">Indexx Hive</h5>
    //               <Typography
    //                 component="p"
    //                 sx={
    //                   location.pathname === "/"
    //                     ? {
    //                         height: "0.07px",
    //                         width: "58px",
    //                         backgroundColor: "var(--dark_text)",
    //                       }
    //                     : null
    //                 }
    //               ></Typography>
    //             </div>
    //           </div>
    //         </Link>
    //       </div>
    //       {/* <div className="col">
    //     <HashLink to="/honeycomb/#honeycomb">
    //         <div className="card">
    //         <img src={honey} className="card-img-top" alt="..."/>
    //         <div className="card-body">
    //             <h5 className="card-title">Honeycomb</h5>
    //         </div>
    //         </div>
    //     </HashLink>
    //     </div> */}
    //       <div className="">
    //         <HashLink to="/honey-bees">
    //           <div className="card">
    //             {seltheme === "dark" ? (
    //               <img src={bee_dark} className="card-img-top" alt="..." />
    //             ) : (
    //               <img src={bee} className="card-img-top" alt="..." />
    //             )}
    //             <div className="card-body">
    //               <h5 className="card-title" style={{ marginTop: "12px" }}>
    //                 Hive Member
    //               </h5>
    //               <Typography
    //                 component="p"
    //                 sx={
    //                   location.pathname === "/honey-bees"
    //                     ? {
    //                         height: "0.07px",
    //                         width: "58px",
    //                         backgroundColor: "var(--dark_text)",
    //                       }
    //                     : null
    //                 }
    //               ></Typography>
    //             </div>
    //           </div>
    //         </HashLink>
    //       </div>

    //       {/* <div className="col">
    //     <HashLink to="/honeycomb/#honeybee">
    //         <div className="card">
    //         <img src={bee} className="card-img-top mt-2" alt="..."/>
    //         <div className="card-body">
    //             <h5 className="card-title">Honey Bee</h5>
    //         </div>
    //         </div>
    //     </HashLink>
    //     </div> */}

    //       <div className="">
    //         <a href="https://cex.indexx.ai">
    //           <div className="card">
    //             {seltheme === "dark" ? (
    //               <img
    //                 src={hive_exch_dark}
    //                 className="card-img-top"
    //                 alt="..."
    //                 style={{ marginTop: "2px" }}
    //               />
    //             ) : (
    //               <img
    //                 src={hive_exch}
    //                 className="card-img-top"
    //                 alt="..."
    //                 style={{ marginTop: "2px" }}
    //               />
    //             )}
    //             <div className="card-body">
    //               <h5 className="card-title">Exchange</h5>
    //               <Typography
    //                 component="p"
    //                 sx={
    //                   location.pathname === "/jar"
    //                     ? {
    //                         height: "0.07px",
    //                         width: "48px",
    //                         backgroundColor: "var(--dark_text)",
    //                       }
    //                     : null
    //                 }
    //               ></Typography>
    //             </div>
    //           </div>
    //         </a>
    //       </div>

    //       <div className="">
    //         <a href="/sign-up">
    //           <div className="card">
    //             {seltheme === "dark" ? (
    //               <img
    //                 src={registration_dark}
    //                 className="card-img-top"
    //                 alt="..."
    //                 style={{
    //                   width: "auto",
    //                 }}
    //               />
    //             ) : (
    //               <img
    //                 src={registration}
    //                 className="card-img-top"
    //                 alt="..."
    //                 style={{
    //                   width: "auto",
    //                 }}
    //               />
    //             )}
    //             <div className="card-body">
    //               <h5 className="card-title">Sign up</h5>
    //               <Typography
    //                 component="p"
    //                 sx={
    //                   location.pathname === "/sign-up"
    //                     ? {
    //                         height: "0.07px",
    //                         width: "58px",
    //                         backgroundColor: "var(--dark_text)",
    //                       }
    //                     : null
    //                 }
    //               ></Typography>
    //             </div>
    //           </div>
    //         </a>
    //       </div>
    //       {/* <div className="col">
    //     <a href="/sign-up">
    //         <div className="card">
    //         <img src={signup} className="card-img-top" alt="..."/>
    //         <div className="card-body">
    //             <h5 className="card-title">Sign Up</h5>
    //         </div>
    //         </div>
    //     </a>
    //     </div>
    //     <div className="col">
    //     <a href="">
    //         <div className="card">
    //         <img src={overview} className="card-img-top" alt="..."/>
    //         <div className="card-body">
    //             <h5 className="card-title">Brand Overview</h5>
    //         </div>
    //         </div>
    //     </a>
    //     </div> */}
    //       {/* <div className="col">
    //     <a href="/process">
    //         <div className="card">
    //         {seltheme === "dark" ?
    //         <img src={pro_dark} className="card-img-top" alt="..."/>
    //         :
    //         <img src={pro} className="card-img-top" alt="..."/>
    //         }
    //         <div className="card-body">
    //             <h5 className="card-title">Process</h5>
    //             <Typography
    //             component='p'
    //             sx={
    //               location.pathname === '/process'
    //                 ? {
    //                   height: '0.07px',
    //                   width: '58px',
    //                   backgroundColor: "var(--dark_text)",
    //                 }
    //                 : null
    //             }
    //           ></Typography>
    //         </div>
    //         </div>
    //     </a>
    //     </div> */}

    //       <div className="">
    //         <Link to="https://indexx.ai/auth/login?redirectWebsiteLink=hive">
    //           <div className="card">
    //             {seltheme === "dark" ? (
    //               <img
    //                 src={man_dark}
    //                 className="card-img-top"
    //                 alt="..."
    //                 width={"50px"}
    //                 height={"41px"}
    //               />
    //             ) : (
    //               <img
    //                 src={man}
    //                 className="card-img-top"
    //                 alt="..."
    //                 width={"50px"}
    //                 height={"41px"}
    //               />
    //             )}
    //             <div className="card-body">
    //               <h5 className="card-title">Log-in</h5>
    //               <Typography
    //                 component="p"
    //                 sx={
    //                   location.pathname ===
    //                   "https://indexx.ai/auth/login?redirectWebsiteLink=hive"
    //                     ? {
    //                         height: "0.07px",
    //                         width: "58px",
    //                         backgroundColor: "var(--dark_text)",
    //                       }
    //                     : null
    //                 }
    //               ></Typography>
    //             </div>
    //           </div>
    //         </Link>
    //       </div>

    //       {/* <div className="col">
    //     <a href="/resource">
    //         <div className="card">
    //         <img src={calendar} className="card-img-top" alt="..."/>
    //         <div className="card-body">
    //             <h5 className="card-title">Resources</h5>
    //         </div>
    //         </div>
    //     </a>
    //     </div>
    //     <div className="col">
    //     <a href="/">
    //         <div className="card">
    //         <img src={book} className="card-img-top" alt="..."/>
    //         <div className="card-body">
    //             <h5 className="card-title">Handbook</h5>
    //         </div>
    //         </div>
    //     </a>
    //     </div> */}
    //       {/* <div className="col" style={{ width: "5%" }}></div> */}

    //       {/* <div className="col" style={{ width: "17%" }}>
    //             <a href='/'>

    //                 <Button variant='contained'
    //                   disableTouchRipple
    //                     sx={{
    //                         backgroundColor: "#FFD000",
    //                         borderRadius: "1px",
    //                         color: "#282828",
    //                         //   width:"30%",
    //                         px: 3,
    //                         py: 1,
    //                         textTransform: "none",
    //                         fontSize: "13px",
    //                         fontWeight: 500,
    //                         boxShadow: "none",
    //                         mt: 3,
    //                         "&:hover": {
    //                             backgroundColor: "#FFB300",
    //                             boxShadow: "none",
    //                         },
    //                     }}
    //                 >
    //                     Honeybee’s HoneyComb
    //                 </Button>
    //             </a>
    //             </div> */}
    //     </div>
    //   </div>
    // </div>
  );
};

export default SubHeader;
